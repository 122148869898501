import { IconTypes } from '#/lib/types';
import { ElementType } from 'react';
import ArrowRight from '../public/icons/arrow-right.svg';
import ChevronRight from '../public/icons/chevron-right.svg';
import ChevronDown from '../public/icons/chevron-down.svg';
import Minus from '../public/icons/minus.svg';
import Menu from '../public/icons/menu.svg';
import Doc from '../public/icons/doc.svg';
import Landing from '../public/icons/s-landingpages.svg';
import Widgets from '../public/icons/s-widgets.svg';
import Question from '../public/icons/question.svg';
import Changelog from '../public/icons/changelog.svg';
import Snippets from '../public/icons/s-snippets.svg';
import StarFilled from '../public/icons/star-filled.svg';
import X from '../public/icons/x.svg';
import Facebook from '../public/icons/facebook.svg';
import Instagram from '../public/icons/instagram.svg';
import Twitter from '../public/icons/twitter.svg';
import GitHub from '../public/icons/github.svg';
import Dribbble from '../public/icons/dribbble.svg';
import Blog from '../public/icons/blog.svg';
import ProThemes from '../public/icons/pro-themes.svg';
import Code from '../public/icons/code.svg';
import DevCode from '../public/icons/dev-code.svg';
import Coin from '../public/icons/coin.svg';
import CoinMember from '../public/icons/coin-member.svg';
import Dashboard from '../public/icons/dashboard.svg';
import Deploy from '../public/icons/deploy.svg';
import Settings from '../public/icons/settings.svg';
import CustomSettings from '../public/icons/custom-settings.svg';
import Support from '../public/icons/support.svg';
import Update from '../public/icons/update.svg';
import Check from '../public/icons/check.svg';
import CheckCircle from '../public/icons/check-circle.svg';
import Dummy from '../public/icons/dummy.svg';
import Eye from '../public/icons/eye.svg';
import Archive from '../public/icons/archive.svg';
import ArrowCircleUp from '../public/icons/arrow-circle-up.svg';
import ArrowsJoin from '../public/icons/arrows-join.svg';
import At from '../public/icons/at.svg';
import BrightnessHalf from '../public/icons/brightness-half.svg';
import ChartLine from '../public/icons/chart-line.svg';
import ChartPie from '../public/icons/chart-pie.svg';
import ChartPie4 from '../public/icons/chart-pie-4.svg';
import Clock from '../public/icons/clock.svg';
import CloudUpload from '../public/icons/cloud-upload.svg';
import CodeDots from '../public/icons/cloud-upload.svg';
import Comments from '../public/icons/comments.svg';
import DeviceAnalytics from '../public/icons/device-analytics.svg';
import DeviceDesktopAnalytics from '../public/icons/device-desktop-analytics.svg';
import DeviceMobile from '../public/icons/device-mobile.svg';
import Directions from '../public/icons/directions.svg';
import Disqus from '../public/icons/disqus.svg';
import Explore from '../public/icons/explore.svg';
import ExternalLink from '../public/icons/external-link.svg';
import EyeCheck from '../public/icons/eye-check.svg';
import EyeOff from '../public/icons/eye-off.svg';
import FilePlus from '../public/icons/file-plus.svg';
import Filter from '../public/icons/filter.svg';
import Forms from '../public/icons/forms.svg';
import Ghost from '../public/icons/ghost.svg';
import Hierarchy from '../public/icons/hierarchy.svg';
import Id from '../public/icons/id.svg';
import InfoCircle from '../public/icons/info-circle.svg';
import InfoSquare from '../public/icons/info-square.svg';
import ListNumbers from '../public/icons/list-numbers.svg';
import MailOpened from '../public/icons/mail-opened.svg';
import MapPin from '../public/icons/map-pin.svg';
import Match from '../public/icons/match.svg';
import Photo from '../public/icons/photo.svg';
import PictureInPictureOn from '../public/icons/picture-in-picture-on.svg';
import Progress from '../public/icons/progress.svg';
import RefreshAlert from '../public/icons/refresh-alert.svg';
import Robot from '../public/icons/robot.svg';
import RotateClockwise from '../public/icons/rotate-clockwise.svg';
import Search from '../public/icons/search.svg';
import Select from '../public/icons/select.svg';
import Server from '../public/icons/server.svg';
import Slideshow from '../public/icons/slideshow.svg';
import Stack from '../public/icons/stack.svg';
import Stethoscope from '../public/icons/stethoscope.svg';
import Table from '../public/icons/table.svg';
import Tags from '../public/icons/tags.svg';
import Template from '../public/icons/template.svg';
import Typography from '../public/icons/typography.svg';
import Urgent from '../public/icons/urgent.svg';
import Users from '../public/icons/users.svg';
import UserCheck from '../public/icons/user-check.svg';
import Image from '../public/icons/image.svg';
import Design from '../public/icons/design.svg';
import Versions from '../public/icons/versions.svg';
import Refresh from '../public/icons/refresh.svg';
import CircleCheck from '../public/icons/circle-check.svg';
import Checks from '../public/icons/checks.svg';
import Mail from '../public/icons/mail.svg';
import Lifebuoy from '../public/icons/lifebuoy.svg';
import Star from '../public/icons/star.svg';
import FileCheck from '../public/icons/file-check.svg';
import Rss from '../public/icons/rss.svg';
import BrightThemes from '../public/icons/bright-themes.svg';
import ThumbUp from '../public/icons/thumb-up.svg'
import Hand from '../public/icons/hand.svg'
import HandClap from '../public/icons/hand-clap.svg'
import Calendar from '../public/icons/calendar.svg'
import Refund from '../public/icons/refund.svg'
import Cards from '../public/icons/cards.svg'
import MailForward from '../public/icons/mail-forward.svg'
import Mouse from '../public/icons/mouse.svg'
import DiscountPerc from '../public/icons/discount.svg'
import Schema from '../public/icons/schema.svg'
import Node from '../public/icons/node.svg'
import Edit from '../public/icons/edit.svg'
import Heart from '../public/icons/heart.svg'
import Popup from '../public/icons/popup.svg'
import LayoutSidebar from '../public/icons/layout-sidebar-right.svg'
import Demo from '../public/icons/demo.svg'
import Download from '../public/icons/download.svg'
import Seo from '../public/icons/seo.svg'
import GoogleFonts from '../public/icons/google-fonts.svg'
import Braces from '../public/icons/braces.svg'
import Autoload from  '../public/icons/autoload.svg'
import Footnotes from  '../public/icons/footnotes.svg'
import Share from  '../public/icons/share.svg'
import Toc from  '../public/icons/toc.svg'
import ZoomIn from  '../public/icons/zoom-in.svg'
import ScrollToTop from '../public/icons/scroll-to-top.svg'
import Password from '../public/icons/password.svg'
import CreditCard from '../public/icons/credit-card.svg'
import Home from '../public/icons/home.svg'
import SquarePlus from '../public/icons/square-plus.svg'
import LockCode from '../public/icons/lock-code.svg'
import ShieldCheck from '../public/icons/shield-check.svg'

import CBlog from '../public/icons/c-blog.svg'
import CDirectory from '../public/icons/c-directory.svg'
import CDocumentation from '../public/icons/c-documentation.svg'
import CJobBoard from '../public/icons/c-job-board.svg'
import CListing from '../public/icons/c-listing.svg'
import CMagazine from '../public/icons/c-magazine.svg'
import CNews from '../public/icons/c-news.svg'
import CNewsletter from '../public/icons/c-newsletter.svg'
import CPhotography from '../public/icons/c-photography.svg'
import CPortfolio from '../public/icons/c-portfolio.svg'
import CStory from '../public/icons/c-story.svg'
import CWriting from '../public/icons/c-writing.svg'
import CPersonal from '../public/icons/c-personal.svg'
import CChangelog from '../public/icons/c-changelog.svg'
import CPodcast from '../public/icons/c-podcast.svg'

import Like from '../public/icons/special/like.svg';
import Lifetime from '../public/icons/special/lifetime.svg';
import Verified from '../public/icons/special/verified.svg';
import Variants from '../public/icons/special/variants.svg';
import Underline from '../public/icons/special/underline.svg';
import Lightning from '../public/icons/special/lightning.svg';
import Discount from '../public/icons/special/discount.svg';
import CheckSpecial from '../public/icons/special/check.svg';

const icons: IconTypes = {
  'arrow-right': ArrowRight,
  'chevron-right': ChevronRight,
  'chevron-down': ChevronDown,
  'minus': Minus,
  'menu': Menu,
  'doc': Doc,
  'landing': Landing,
  'widgets': Widgets,
  'question': Question,
  'changelog': Changelog,
  'snippets': Snippets,
  'star-filled': StarFilled,
  'x': X,
  'facebook': Facebook,
  'twitter': Twitter,
  'instagram': Instagram,
  'github': GitHub,
  'dribbble': Dribbble,
  'blog': Blog,
  'pro-themes': ProThemes,
  'code': Code,
  'dev-code': DevCode,
  'coin': Coin,
  'coin-member': CoinMember,
  'dashboard': Dashboard,
  'deploy': Deploy,
  'settings': Settings,
  'custom-settings': CustomSettings,
  'support': Support,
  'update': Update,
  'check': Check,
  'check-circle': CheckCircle,
  'dummy': Dummy,
  'eye': Eye,
  'archive': Archive,
  'arrow-circle-up': ArrowCircleUp,
  'arrows-join': ArrowsJoin,
  'at': At,
  'brightness-half': BrightnessHalf,
  'chart-line': ChartLine,
  'chart-pie-4': ChartPie4,
  'chart-pie': ChartPie,
  'clock': Clock,
  'cloud-upload': CloudUpload,
  'code-dots': CodeDots,
  'comments': Comments,
  'device-analytics': DeviceAnalytics,
  'device-desktop-analytics': DeviceDesktopAnalytics,
  'device-mobile': DeviceMobile,
  'directions': Directions,
  'disqus': Disqus,
  'explore': Explore,
  'external-link': ExternalLink,
  'eye-check': EyeCheck,
  'eye-off': EyeOff,
  'file-plus': FilePlus,
  'filter': Filter,
  'forms': Forms,
  'ghost': Ghost,
  'hierarchy': Hierarchy,
  'id': Id,
  'info-circle': InfoCircle,
  'info-square': InfoSquare,
  'list-numbers': ListNumbers,
  'mail-opened': MailOpened,
  'map-pin': MapPin,
  'match': Match,
  'photo': Photo,
  'picture-in-picture-on': PictureInPictureOn,
  'progress': Progress,
  'refresh-alert': RefreshAlert,
  'robot': Robot,
  'rotate-clockwise': RotateClockwise,
  'search': Search,
  'select': Select,
  'server': Server,
  'slideshow': Slideshow,
  'stack': Stack,
  'stethoscope': Stethoscope,
  'table': Table,
  'tags': Tags,
  'template': Template,
  'typography': Typography,
  'urgent': Urgent,
  'user-check': UserCheck,
  'users': Users,
  'image': Image,
  'design': Design,
  'versions': Versions,
  'refresh': Refresh,
  'circle-check': CircleCheck,
  'checks': Checks,
  'mail': Mail,
  'lifebuoy': Lifebuoy,
  'star': Star,
  'verified': Verified,
  'file-check': FileCheck,
  'lifetime': Lifetime,
  'like': Like,
  'variants': Variants,
  'bright-themes': BrightThemes,
  'rss': Rss,
  'thumb-up': ThumbUp,
  'underline': Underline,
  'lightning': Lightning,
  'hand': Hand,
  'hand-clap': HandClap,
  'discount': Discount,
  'check-special': CheckSpecial,
  'calendar': Calendar,
  'refund': Refund,
  'cards': Cards,
  'mail-forward': MailForward,
  'mouse': Mouse,
  'discount-perc': DiscountPerc,
  'schema': Schema,
  'node': Node,
  'edit': Edit,
  'heart': Heart,
  'popup': Popup,
  'layout-sidebar': LayoutSidebar,
  'demo': Demo,
  'download': Download,
  'seo': Seo,
  'google-fonts': GoogleFonts,
  'braces': Braces,
  'autoload': Autoload,
  'footnotes': Footnotes,
  'share': Share,
  'toc': Toc,
  'zoom-in': ZoomIn,
  'scroll-to-top': ScrollToTop,
  'password': Password,
  'credit-card': CreditCard,
  'home': Home,
  'square-plus': SquarePlus,
  'lock-code': LockCode,
  'shield-check': ShieldCheck,

  'c-blog': CBlog,
  'c-directory': CDirectory,
  'c-documentation': CDocumentation,
  'c-job-board': CJobBoard,
  'c-listing': CListing,
  'c-magazine': CMagazine,
  'c-news': CNews,
  'c-newsletter': CNewsletter,
  'c-photography': CPhotography,
  'c-portfolio': CPortfolio,
  'c-story': CStory,
  'c-writing': CWriting,
  'c-personal': CPersonal,
  'c-changelog': CChangelog,
  'c-podcast': CPodcast
};

const Icon = ({
  name,
  className,
  strokeWidth,
  viewBox,
  ...props
}: {
  name: string;
  className?: string;
  strokeWidth?: string;
  viewBox?: string;
}) => {
  let Icon: ElementType = icons.hasOwnProperty(name)
    ? icons[name]
    : icons['dummy'];
  return (
    <Icon
      {...props}
      className={className}
      strokeWidth={strokeWidth ? strokeWidth : '2'}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
    />
  );
};

export default Icon;
